import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import ServerStatusPage from './pages/ServerStatus';
import NotFoundPage from './pages/NotFoundPage';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import './i18n'
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <div className="flex flex-col min-h-screen bg-gray-100 ">
            <Header />

            <main className="flex-grow bg-gray-100">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<ServerStatusPage />} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </BrowserRouter>
            </main>

            <Footer />
        </div>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
