import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import japaneseLogo from '../../assets/logos/open-kakomon-logo-jp.svg'
import englishLogo from '../../assets/logos/open-kakomon-logo-en.svg'
import fileIcon from '../../assets/icons/Files.svg'
import uploadIcon from '../../assets/icons/File_Upload.svg'

// https://tailwindui.com/components/marketing/elements/headers

const Header = () => {
    const { i18n } = useTranslation('home');
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [mobileProductMenuOpen, setMobileProductMenuOpen] = useState(false)


    const products = [
        { name: 'Browse Papers', description: 'Browse popular/recently added exam papers', href: '/exams', icon: fileIcon, iconAlt: "files icon" },
        { name: 'Upload', description: 'Share exam paper with your community', href: '/share-exam', icon: uploadIcon, iconAlt: "upload icon" },
    ]

    const menuItems = [
        {
            name: "Features",
            href: '/share-exam'
        },
        {
            name: "Contact",
            href: '/contact'
        },
    ]

    return (
        <>
            <header class="bg-white">
                <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 md:px-8" aria-label="Global">
                    <div class="flex md:flex-1">
                        <a href="/" class="-m-1.5 p-1.5">
                            <span class="sr-only">Open Kakomon</span>
                            <img class="h-10 w-auto" src={i18n.language === 'en' ? englishLogo : japaneseLogo} alt="open-kakomon logo" />
                        </a>
                    </div>
                    <div class="flex md:hidden">
                        <button
                            type="button"
                            class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}>
                            <span class="sr-only">Open main menu</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                    </div>
                    <div class="hidden md:flex md:gap-x-12">
                        <div class="relative">
                            <button type="button" class="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" aria-expanded="false"
                                onClick={() => setMobileProductMenuOpen(!mobileProductMenuOpen)}>
                                Product
                                <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                </svg>
                            </button>

                            {
                                mobileProductMenuOpen &&
                                <div class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                    <div class="p-4">
                                        {
                                            products.map((object, id) =>
                                                <div class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                                                    <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                        {/* <svg class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                                                        </svg> */}
                                                        <img src={object.icon} alt={object.iconAlt} />
                                                    </div>
                                                    <div class="flex-auto">
                                                        <a href={object.href} class="block font-semibold text-gray-900">
                                                            {object.name}
                                                            <span class="absolute inset-0"></span>
                                                        </a>
                                                        <p class="mt-1 text-gray-600">
                                                            {object.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        {menuItems.map((object, id) =>
                            <a href={object.href}
                                class="text-sm font-semibold leading-6 text-gray-900 border-b-2 border-transparent hover:border-blue-500"
                                key={id}>
                                {object.name}
                            </a>)}

                    </div>
                    <div class="hidden md:flex md:flex-1 md:justify-end">
                        <a href="/login" class="text-sm font-semibold leading-6 text-gray-900">
                            Log in
                            <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </nav>
                {
                    mobileMenuOpen &&
                    <div class="md:hidden" role="dialog" aria-modal="true">
                        <div class="fixed inset-0 z-10"></div>
                        <div class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                            <div class="flex items-center justify-between">
                                <a href="/contact" class="-m-1.5 p-1.5">
                                    <span class="sr-only">Open Kakomon</span>
                                    <img class="h-10 w-auto" src={i18n.language === 'en' ? englishLogo : japaneseLogo} alt="open-kakomon logo" />
                                </a>
                                <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(false)}>
                                    <span class="sr-only">Close menu</span>
                                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div class="mt-6 flow-root">
                                <div class="-my-6 divide-y divide-gray-500/10">

                                    <div class="space-y-2 py-6">
                                        <div class="-mx-3">
                                            <button type="button" class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50" aria-controls="disclosure-1" aria-expanded="false"
                                                onClick={() => setMobileProductMenuOpen(!mobileProductMenuOpen)}>
                                                Product
                                                <svg class="h-5 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                                </svg>
                                            </button>
                                            {
                                                mobileProductMenuOpen &&
                                                products.map((object, id) =>
                                                    <a href={object.href} class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">{object.name}</a>
                                                )

                                            }
                                        </div>
                                        {menuItems.map((object, id) =>
                                            <a href={object.href}
                                                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                key={id}>
                                                {object.name}
                                            </a>)}
                                    </div>
                                    <div class="py-6">
                                        <a href="/login"
                                            class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                            Log in
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </header>
        </>
    )
}

export default Header;
